import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/css-introduction",
  "date": "2014-10-27",
  "title": "CSS INTRODUCTION",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "Cascading Style Sheets (CSS) is a style sheet language used for describing the look and formatting of a document written in a markup language, most often used to change the style of web pages written in HTML. CSS is designed primarily to enable the separation of document content from document presentation, including elements such as the layout, colors, and fonts."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Using CSS`}</h2>
    <p>{`CSS can be added in different ways.`}</p>
    <h2>{`Slope Style`}</h2>
    <ul>
      <li parentName="ul">{`Inline style attribute`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1 style="color: #98c7d4;">CSS Cross Country</h1>
`}</code></pre>
    <ul>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`<head>`}</inlineCode>{`	`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<head>
    <style>
        h1 { color: #98c7d4; }
    </style>
</head>
`}</code></pre>
    <ul>
      <li parentName="ul">{`External `}<inlineCode parentName="li">{`<head>`}</inlineCode></li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<head>
    <title>CSS Cross Country</title>
    <link rel="stylesheet" href="styles.css" />
</head>
`}</code></pre>
    <h2>{`Selectors`}</h2>
    <p>{`Primary DOM selectors:`}</p>
    <h3>{`Element selector`}</h3>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1 class="intro" id="header">Nice and Toasty</h1>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h1 {
    color: #aba4ac;
    margin-bottom: 10px;
}
`}</code></pre>
    <h3>{`Class selector`}</h3>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1 class="intro" id="header">Nice and Toasty</h1>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.intro {
    color: #aba4ac;
    margin-bottom: 10px;
}
`}</code></pre>
    <h3>{`ID selector`}</h3>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1 class="intro" id="header">Nice and Toasty</h1>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`#header {
    color: #aba4ac;
    margin-bottom: 10px;
}
`}</code></pre>
    <h3>{`Compound selectors`}</h3>
    <p><strong parentName="p">{`HTML`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1 class="intro" id="header">Nice and Toasty</h1>
`}</code></pre>
    <p><strong parentName="p">{`CSS`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`h1#header {
    color: #aba4ac;
    margin-bottom: 10px;
}
`}</code></pre>
    <h2>{`Cascade Order`}</h2>
    <p>{`Style priority is determined by position in site:`}</p>
    <ul>
      <li parentName="ul">{`External `}<inlineCode parentName="li">{`<link>`}</inlineCode></li>
      <li parentName="ul">{`In the `}<inlineCode parentName="li">{`<head>`}</inlineCode></li>
      <li parentName="ul">{`Inline style attribute`}</li>
      <li parentName="ul">{`Using !important`}</li>
    </ul>
    <p>{`Above order represents Increasing Priority.`}</p>
    <p>{`Priority is also dependent on position in document:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.intro {
    color: #444245;
}
.intro {
    color: #dddadd;
}
`}</code></pre>
    <p>{`the second color definition for `}<strong parentName="p">{`.intro`}</strong>{` overrides the first.`}</p>
    <p>{`Non-conflicting properties will be combined:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.intro {
    color: #dddadd;
}
.intro {
    margin-bottom: 5px;
    width: 900px;
}
`}</code></pre>
    <p>{`This can ve combined into:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.intro {
    color: #dddadd;
    margin-bottom: 5px;
    width: 900px;
}
`}</code></pre>
    <h2>{`Floating Left & Right`}</h2>
    <p>{`Let's look at how `}<strong parentName="p">{`float`}</strong>{` works. Look at the following markup:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<article>
    <img src="ski.jpg" alt="Ski!" />
    To successfully ski, simply do not fall.
</article>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`img {
    float: left;
}
`}</code></pre>
    <p>{`So, floating removes elements from the document flow and moves
them to a specified edge. The other content within the parent element will wrap around floats.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-10-27-css-introduction/float.png",
        "alt": "Float"
      }}></img></p>
    <pre><code parentName="pre" {...{}}>{`float: left / right / none
`}</code></pre>
    <h3>{`Stacking order`}</h3>
    <p>{`Floated elements stack up to the parent edge, then move down to
the next available edge.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2014-10-27-css-introduction/stack.jpg",
        "alt": "Stack"
      }}></img></p>
    <p>{`Take care with elements that have differing heights - the first
available edge isn't always below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<article>
    <img class="ski" src="ski.jpg" alt="Ski!" />
    <img class="sled" src="sled.jpg" alt="Sled!" />
</article>
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.ski {
    float: right;
}
.sled {
    float: left;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/images/2014-10-27-css-introduction/leftleft.jpg",
        "alt": "Left left"
      }}></img></p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.ski {
    float: right;
}
.sled {
    float: right;
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/images/2014-10-27-css-introduction/leftright.jpg",
        "alt": "Left right"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      